import { createReducer } from "@reduxjs/toolkit";
import { fetchDeliverers } from "../actions/Deliverers";

const deliverers = createReducer(
  { deliverers: [] },
  {
    [fetchDeliverers]: (state, action) => ({
      deliverers: action.payload.data,
    }),
  }
);

export default deliverers;
