import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Notification from "./Notification";
import {
  PROVIDER_PAYMENT_REQUEST_NOTIF,
  PROVIDER_PAYMENT_APPROVED_NOTIF,
} from "../../utils/constants";

const NotificationsList = ({ afterNotificationAction, list }) => {
  const history = useHistory();
  const createAction = useCallback((actionCallback) => {
    return () => {
      actionCallback();
      afterNotificationAction();
    };
  }, []);

  return (
    <ul className="notification_ul">
      {list.length > 0 ? (
        <>
          {list.map((item, index) => {
            let icon, title, subTitle, action;
            switch (item.type) {
              case PROVIDER_PAYMENT_REQUEST_NOTIF:
                icon = <i className="fas fa-hand-holding-usd"></i>;
                title = item.entity.name;
                subTitle =
                  "ha solicitado el pago de ventas. [ref: " + item.target + "]";
                action = createAction(() => {
                  history.push("/payments/" + item.target);
                });
                break;
              case PROVIDER_PAYMENT_APPROVED_NOTIF:
                icon = <i className="fas fa-check-circle"></i>;
                title = "Solicitud de pago aprobada";
                subTitle = `Llegará un cheque de pago en menos de 24h. [ref: ${item.target}]`;
                action = null;
                break;
            }
            return (
              <Notification
                key={index}
                icon={icon}
                title={title}
                subTitle={subTitle}
                date={item.sentAt}
                seen={item.seen}
                action={action}
                type={item.type}
              />
            );
          })}
        </>
      ) : (
        <span className="d-flex justify-content-center p-5 text-muted">
          No hay notificaciones
        </span>
      )}
    </ul>
  );
};

export default NotificationsList;
