import { storage } from "../utils/Firebase";

export const generateRandonValue = (string, length) => {
  let result = "";
  let charactersLength = string.length;
  for (var i = 0; i < length; i++) {
    result += string.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateRandomString = (len) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-=";
  return generateRandonValue(characters, len);
};

export const uploadImage = (folder = "", files = []) => {
  const uploadFilePromise = (file) => {
    return new Promise((resolve, reject) => {
      const imagePath = `images/${folder}/${file.name}`;
      const uploadTask = storage.ref().child(imagePath).put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => reject(error),
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => resolve({ imagePath, downloadURL }));
        }
      );
    });
  };

  return Promise.all(files.map((file) => uploadFilePromise(file)));
};

//Auth Error Code Handler
export const loginErrorMessageHandler = (errorCode) => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "Ingresa un correo válido.";
    case "auth/user-not-found":
      return "No existe ningún usuario con este correo.";
    case "auth/wrong-password":
      return "Correo o contraseña incorrectos.";
    case "auth/user-disabled":
      return "Tu cuenta ha sido inhabilitada. Si crees que esto es un error ponte en contacto con la administración.";
    case "auth/not-enough-level":
      return "Tu cuenta ha sido inhabilitada. Si crees que esto es un error ponte en contacto con la administración.";
    case "auth/too-many-requests":
      return "Esta cuenta ha sido temporalmente inhabilitada debido a muchos intentos de inicio de sesión. Intenta de nuevo más tarde.";
    default:
      return "Algo salió mal, intenta de nuevo.";
  }
};

// Calculate percentage to use based on product price
export const calculatePercentage = (price) => {
  //return price <= 100 ? 0.3 : price > 100 && price <= 500 ? 0.26 : 0.16;
  return  0.15;
};
// Calculate our profit based on price
export const calculateProfit = (value) => {
  const percentage = calculatePercentage(value);
  return Math.ceil(value * percentage);
};
// Total price calculator
export const calculateTotal = (price) => {
  const profit = calculateProfit(price);
  return Math.ceil(price - profit);
};

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param oldNumber The initial value
 * @param newNumber The value that changed
 *
 * @returns positive value if percentage value has decreased
 * and returns negative value if it has increased
 */
export const getPercentageChange = (oldNumber, newNumber) => {
  var decreaseValue = oldNumber - newNumber;
  return (decreaseValue / oldNumber) * 100;
};
