import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../components/Footer";
import { loginErrorMessageHandler } from "../utils/functions";

const Login = ({ forbiddenAccess, isSubmitting, onSubmit }) => {
  const [data, setData] = useState({
    email: "",
    password: "",
    errorCode: "",
  });
  const history = useHistory();

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setData({ ...data, errorCode: "" });
    onSubmit(true);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        history.push("/");
      })
      .catch(function (error) {
        console.error(error);
        setData({ ...data, errorCode: error.code });
        onSubmit(false);
      });
  };

  return (
    <div className="container">
      <div className="d-flex row min-vh-100 align-items-center">
        <div className="col-11 col-md-8 col-lg-5 mx-auto">
          <div className="mb-5 text-center">
            <img src={"/logo.svg"} width={70} height="auto" />
          </div>

          <div className="login-form card p-5">
            <h3 className="m-0 mb-4">Identificarse</h3>
            {data.errorCode !== "" && (
              <Alert variant="danger">
                {loginErrorMessageHandler(data.errorCode)}
              </Alert>
            )}
            {forbiddenAccess.hasError && (
              <Alert variant="danger">{forbiddenAccess.message}</Alert>
            )}
            <form onSubmit={handleFormSubmit}>
              <div className="form-group mb-2">
                <label htmlFor="email">Correo</label>
                <input
                  type="text"
                  value={data.email}
                  onChange={handleInputChange}
                  className="form-control"
                  id="email"
                  name="email"
                />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="password">Contraseña</label>
                <input
                  type="password"
                  value={data.password}
                  onChange={handleInputChange}
                  className="form-control"
                  id="password"
                  name="password"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="d-flex btn btn-primary align-items-center"
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="white"
                      size="sm"
                      className="mr-2"
                    />
                    Ingresando...
                  </>
                ) : (
                  "Ingresar"
                )}
              </button>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Login;
