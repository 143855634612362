import { createReducer } from "@reduxjs/toolkit";
import { fetchProviders } from "../actions/Providers";

const providers = createReducer(
  { providers: [] },
  {
    [fetchProviders]: (state, action) => ({
      providers: action.payload.data,
    }),
  }
);

export default providers;
