import { firestore } from "../utils/Firebase";

export default async function fetchAllData(collections = []) {
  const promises = [];
  for (let collection of collections) {
    promises.push(firestore.collection(collection).get());
  }
  const results = await Promise.all(promises);
  const data = results.map((result) =>
    result.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  );
  return data;
}
