import React from "react";

const Footer = () => {
  return (
    <footer className="app-footer container mx-lg-0 py-5">
      &copy; {new Date().getFullYear()} Gur&uacute; Delivery
    </footer>
  );
};

export default Footer;
