import { createReducer } from "@reduxjs/toolkit";
import { addNotification, removeNotification } from "../actions/Notifications";

const notifications = createReducer([], {
  [addNotification]: (state, action) => [
    { id: new Date().getTime(), ...action.payload },
    ...state,
  ],
  [removeNotification]: (state, action) =>
    [...state].filter((notification) => notification.id !== action.payload),
});

export default notifications;
