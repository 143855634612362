import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-bootstrap/Toast";
import { removeNotification } from "../actions/Notifications";

const Notifications = () => {
  const notifications = useSelector((state) => state.Notifications);
  const dispatch = useDispatch();

  const handleRemoveNotification = (id) => dispatch(removeNotification(id));

  return (
    <div
      className="notifications-container"
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "absolute",
        right: "64px",
        bottom: "64px",
        minHeight: "100px",
      }}
    >
      {notifications.length > 0 && (
        <>
          {notifications.map((notification) => {
            return (
              <Toast
                key={notification.id}
                onClose={() => handleRemoveNotification(notification.id)}
                delay={8000}
                autohide
              >
                <Toast.Header>
                  <img
                    src={notification.data.icon}
                    width={24}
                    height={24}
                    className="rounded-circle mr-2"
                    alt="user-photo"
                  />
                  <strong className="mr-auto">
                    {notification.data.title + " " + notification.id}
                  </strong>
                </Toast.Header>
                <Toast.Body>{notification.data.body}</Toast.Body>
              </Toast>
            );
          })}
          <div></div>
        </>
      )}
    </div>
  );
};

export default Notifications;
