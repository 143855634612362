import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const BaseModal = ({
  children,
  isProcessing = false,
  visible,
  title,
  onAccept,
  onClose,
  proceedTitle = "Aceptar",
  cancelTitle = "Cancelar",
  size = "md",
}) => {
  return (
    <Modal show={visible} onHide={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children ?? "Algo salió mal."}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={isProcessing}
          onClick={() => {
            onAccept();
          }}
        >
          {isProcessing ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            proceedTitle
          )}
        </Button>
        <Button variant="secondary" disabled={isProcessing} onClick={onClose}>
          {cancelTitle}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseModal;
