import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Notifications from "../components/Notifications";

const MainLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <main className="main w-100">
        <Header />
        <div className="container mx-lg-0">{children}</div>
        <Footer />
      </main>
      <Notifications />
    </>
  );
};

export default MainLayout;
