import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../utils/Firebase";
import { update } from "../actions/AuthActions";
import { appSettings } from "../actions/Settings";
import { fetchProducts } from "../actions/Products";
import ProfileNavigation from "./ProfileNavigation/ProfileNavigation";
import OpenAppModal from "./Modals/OpenAppModal";
import OpenCheckBox from "./Checks/OpenCheckBox";

const Header = () => {
  const [modalVisible, setModalVisible] = useState({
    store: false,
    app: false,
  });
  const location = useLocation();
  const history = useHistory();
  const [auth, products, settings] = useSelector((state) => [
    state.auth,
    state.Products,
    state.Settings,
  ]);
  const dispatch = useDispatch();

  const showSidebarMenu = () => {
    document.getElementById("sidebar").classList.add("active");
    document.getElementById("overlay").classList.add("active");
  };

  const canShowGoBack = () =>
    location.pathname.slice(1).split("/").length >= 3 ? true : false;

  const changeAppActiveStatus = async (status) => {
    await firestore
      .collection("settings")
      .doc("client")
      .update({ active: status });

    dispatch(appSettings({ ...settings, active: status }));
  };

  const closeApp = async () => {
    changeAppActiveStatus(false);
  };

  const closeStore = async () => {
    const batch = firestore.batch();

    const providerProducts = products.reduce((acc, product) => {
      if (product.provider.id === auth.user.id) {
        if (product.active) {
          const docRef = firestore.collection("products").doc(product.id);
          batch.update(docRef, { active: false });
        }

        acc.push({
          ...product,
          active: false,
        });
      }
      return acc;
    }, []);

    batch.update(firestore.collection("providers").doc(auth.user.id), {
      isOpen: false,
    });

    await batch.commit();
    dispatch(fetchProducts(providerProducts));
    dispatch(update({ ...auth.user, isOpen: false }));
  };

  const closeModal = () => {
    setModalVisible({ store: false, app: false });
  };

  return (
    <header>
      <nav className="navBar">
        <div className="navbar_left">
          {canShowGoBack() && (
            <button
              id="goBack"
              className="btn btn-dark rounded-pill"
              onClick={() => history.goBack()}
            >
              <i className="fas fa-chevron-left"></i> Regresar
            </button>
          )}

          <button
            id="toggleSideBar"
            className="btn btn-dark d-lg-none"
            onClick={showSidebarMenu}
          >
            <i className="fas fa-equals"></i>
          </button>

          <div className="d-flex ml-2 ml-lg-0 align-items-center">
            {auth.user.isProvider ? (
              <>
                <div className="px-3 py-2 mr-3 border border-primary rounded font-weight-bold">
                  <i className="fas fa-store text-primary mr-2" />
                  {auth.user.companyName}
                </div>
                <OpenCheckBox
                  label={`${auth.user.isOpen ? "Cerrar " : "Abrir "} Tienda`}
                  checked={auth.user.isOpen}
                  onChange={async (e) => {
                    e.preventDefault();
                    if (e.target.checked === false) {
                      setModalVisible({ ...modalVisible, store: true });
                    } else {
                      await firestore
                        .collection("providers")
                        .doc(auth.user.id)
                        .update({ isOpen: true });
                      dispatch(update({ ...auth.user, isOpen: true }));
                      history.push("/products");
                    }
                  }}
                />
              </>
            ) : (
              <div className="px-3 py-2 bg-white rounded">
                <OpenCheckBox
                  label={settings.active ? "Cerrar App" : "Abrir App"}
                  checked={settings.active}
                  onChange={async (e) => {
                    e.preventDefault();
                    if (e.target.checked === false)
                      setModalVisible({ ...modalVisible, app: true });
                    else changeAppActiveStatus(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <ProfileNavigation user={auth.user} />
      </nav>
      <OpenAppModal
        title="Cerrar Tienda"
        visible={modalVisible.store}
        onAccept={closeStore}
        onClose={closeModal}
      >
        Al cerrar tu tienda todos tus productos se inhabilitarán en la app de
        Gurú. Deseas continuar?
      </OpenAppModal>
      <OpenAppModal
        title="Cerrar App"
        visible={modalVisible.app}
        onAccept={closeApp}
        onClose={closeModal}
      >
        Al cerrar la app ningún usuario podrá acceder a los productos. Deseas
        continuar?
      </OpenAppModal>
    </header>
  );
};

export default Header;
