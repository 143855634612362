import React from "react";
import * as Yup from "yup";

// App
export const APP_NAME = "Gurú Delivery";
//Upload Consts
export const IMAGE_SIZE = 500 * 1024;
export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

//Regex
export const PHONE_REGEX = /^(2|3|8|9)\d{3}-?\d{4}$/;

//Date Consts
export const WEEK_DAYS_ES = {
  weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
  weekdaysShort: "Mon_Tue_Wed_Thu_Fri_Sat_Sun".split("_"),
};

export const MONTHS_ES = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

//Initial values
export const PROVIDER_INITIAL_VALUES = {
  companyName: "",
  companyPhone: "",
  companyAltPhone: "",
  companyAddress: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  rtn: "",
};

export const DELIVERER_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  phone: "",
  altPhone: "",
  address: "",
  driverLicense: "",
  expirationDate: "",
  photo: "",
  email: "",
  password: "",
};

export const ADMIN_MENU = [
  {
    title: "Inicio",
    path: "/",
    icon: <i className="far fa-list-alt"></i>,
    exact: true,
  },
  {
    title: "Creator",
    icon: <i className="far fa-edit"></i>,
    path: "/creator",
  },
  {
    title: "Usuarios",
    icon: <i className="fas fa-users"></i>,
    path: "/users",
  },
  {
    title: "Reportes",
    icon: <i className="fas fa-chart-bar"></i>,
    path: "/reports",
  },
  {
    title: "Pagos",
    icon: <i className="fas fa-wallet"></i>,
    path: "/payments",
  },
];

export const PROVIDER_MENU = [
  {
    title: "Ordenes",
    path: "/",
    icon: <i className="fas fa-concierge-bell"></i>,
    exact: true,
  },
  {
    title: "Productos",
    path: "/products",
    icon: <i className="fas fa-clipboard-check"></i>,
  },
  {
    title: "Historial",
    path: "/history",
    icon: <i className="fas fa-tasks"></i>,
  },
];

//Notifications
export const PROVIDER_PAYMENT_REQUEST_NOTIF = "01";
export const PROVIDER_PAYMENT_APPROVED_NOTIF = "02";
//Payments
export const MIN_AMOUNT_FOR_PAYMENT_REQ = 1000;

//Order Status Button
export const STATUSES = {
  received: {
    key: 1,
    type: "received",
    text: "Pendiente",
    class: "text-primary",
  },
  preparing: {
    key: 2,
    type: "preparing",
    text: "En preparación",
    class: "text-danger",
  },
  sent: { key: 3, type: "sent", text: "Enviado", class: "text-warning" },
  delivered: {
    key: 4,
    type: "delivered",
    text: "Entregado",
    class: "text-success",
  },
};

//Validations
export const PROVIDER_VALIDATION_SCHEMA = Yup.object().shape({
  companyName: Yup.string()
    .required("Ingresa un nombre")
    .min(3, "El nombre debe tener 5 caracteres minimo")
    .max(40, "El nombre no debe tener mas de 40 caracteres"),
  companyPhone: Yup.string()
    .required("Ingresa un numero de telefono")
    .matches(PHONE_REGEX, "Ingresa un numero valido"),
  companyAltPhone: Yup.string().matches(
    PHONE_REGEX,
    "Ingresa un numero valido"
  ),
  companyAddress: Yup.string().required("Ingresa la direccion"),
  rtn: Yup.mixed()
    .required("Selecciona la imagen del RTN")
    .test(
      "fileSize",
      "Este archivo es demasido grande",
      (value) => value && value.size <= IMAGE_SIZE
    )
    .test(
      "fileFormat",
      "Este formato de archivo no es permitido",
      (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
    ),
  firstName: Yup.string()
    .required("Ingresa el nombre")
    .min(2, "El nombre debe tener 3 caracteres minimo")
    .max(35, "El nombre no debe tener mas de 15 caracteres"),
  lastName: Yup.string()
    .required("Ingresa el apellido")
    .min(2, "El apellido debe tener 3 caracteres minimo")
    .max(35, "El apellido no debe tener mas de 15 caracteres"),
  phone: Yup.string()
    .required("Ingresa un numero de telefono")
    .matches(PHONE_REGEX, "Ingresa un numero valido"),
  email: Yup.string()
    .required("Ingresa un email")
    .email("Ingresa un correo valido"),
});

export const DELIVERER_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .required("Ingresa el nombre")
    .min(2, "El nombre debe tener 3 caracteres minimo")
    .max(35, "El nombre no debe tener mas de 15 caracteres"),
  lastName: Yup.string()
    .required("Ingresa el apellido")
    .min(2, "El apellido debe tener 3 caracteres minimo")
    .max(35, "El apellido no debe tener mas de 15 caracteres"),
  phone: Yup.string()
    .required("Ingresa un numero de telefono")
    .matches(PHONE_REGEX, "Ingresa un numero valido"),
  altPhone: Yup.string().matches(PHONE_REGEX, "Ingresa un numero valido"),
  address: Yup.string().required("Ingresa la direccion"),
  photo: Yup.mixed()
    .required("Selecciona una imágen para la foto de perfil")
    .test(
      "fileSize",
      "Este archivo es demasido grande",
      (value) => value && value.size <= IMAGE_SIZE
    )
    .test(
      "fileFormat",
      "Este formato de archivo no es permitido",
      (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
    ),
  driverLicense: Yup.mixed()
    .required("Selecciona una imágen para la licencia")
    .test(
      "fileSize",
      "Este archivo es demasido grande",
      (value) => value && value.size <= IMAGE_SIZE
    )
    .test(
      "fileFormat",
      "Este formato de archivo no es permitido",
      (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
    ),
  expirationDate: Yup.date().required(
    "Especifica la fecha de expiración de la licencia"
  ),
  email: Yup.string()
    .required("Ingresa un email")
    .email("Ingresa un correo valido"),
  password: Yup.string()
    .required("Ingresa una contraseña")
    .min(6, "La contraseña debe tener 6 caracteres minimo")
    .max(35, "El contraseña no debe tener mas de 35 caracteres"),
});
