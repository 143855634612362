import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ToastBox from "react-toastbox";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import firebase, { firestore, messaging } from "./utils/Firebase";
import fetchAllData from "./actions/FetchAllData";
import { login } from "./actions/AuthActions";
import { fetchProviders } from "./actions/Providers";
import { fetchProducts } from "./actions/Products";
import { fetchUsers } from "./actions/Users";
import { fetchDeliverers } from "./actions/Deliverers";
import { addNotification } from "./actions/Notifications";
import { appSettings } from "./actions/Settings";
import Login from "./layouts/Login";
import Routes from "./RoutesHandler";
TimeAgo.addDefaultLocale(es);

const App = () => {
  const [audio, setAudio] = useState(
    new Audio("./assets/sounds/notification.mp3")
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ hasError: false, message: "" });
  const [isNotifying, setIsNotifying] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [auth, notifications] = useSelector((state) => [
    state.auth,
    state.Notifications,
  ]);
  const dispatch = useDispatch();
  const notificationAudio = useRef(null);
  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        //Get user Claim to verify his accesLevel
        const userToken = await user.getIdTokenResult();
        // Forbidden (Not allowed to log in)
        // Provider: 3
        // Admin: 10
        if (userToken.claims.accessLevel < 3) {
          await firebase.auth().signOut();
          setError({
            ...error,
            hasError: true,
            message: "Tu cuenta no tiene permisos para acceder a esta área",
          });
          handleLoginSubmit(false);
          return;
        }

        //Request notification permission
        const permission = await Notification.requestPermission();
        //User has granted notification permission
        const token =
          permission === "granted" ? await messaging.getToken() : null;

        let sessionUser = null;

        //Add notifications listener
        messaging.onMessage((payload) => {
          audio.play();
          dispatch(addNotification(payload.notification));
        });

        // Create session user Object
        if (userToken.claims.accessLevel === 3) {
          //Provider user
          const docRef = firestore.collection("providers").doc(user.uid);
          const doc = await docRef.get();
          sessionUser = { id: user.uid, ...doc.data(), isProvider: true };
          // granted notifications permissions
          if (token) {
            sessionUser.messagingToken = token;
            // Update new tokens list
            await docRef.set(
              { messagingToken: sessionUser.messagingToken },
              { merge: true }
            );
          }
        } else {
          // Administrator user
          sessionUser = {
            id: user.uid,
            name: user.displayName,
            photo: user.photoURL,
            isProvider: false,
          };
        }

        sessionUser.accessLevel = userToken.claims.accessLevel;

        // Let's log the user in.
        dispatch(login(sessionUser));

        // Fetch all data
        const data = await fetchAllData([
          "providers",
          "products",
          //"users",
          "deliverers",
        ]);
        dispatch(fetchProviders(data[0]));
        dispatch(fetchProducts(data[1]));
        //dispatch(fetchUsers(data[2]));
        dispatch(fetchDeliverers(data[2]));

        const usersResult = await firestore
          .collection("users")
          .orderBy("createdAt", "desc")
          .limit(25)
          .get();

        const users = usersResult.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const lastUser = users[users.length - 1];

        dispatch(fetchUsers(users, lastUser));

        const settingsResult = await firestore
          .collection("settings")
          .doc("client")
          .get();

        dispatch(appSettings({ ...settingsResult.data() }));

        // Stop loading icon in login form
        handleLoginSubmit(false);

        // Hide the loading icon
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const handleLoginSubmit = (value) => setIsSubmitting(value);
  return (
    <div className="app min-vh-100 overflow-hidden">
      <Router>
        {isLoading ? (
          <div className="loader">
            <div className="d-block">
              {/* <Spinner animation="border" variant="primary" /> */}
              <img
                src={"/logo.svg"}
                width={70}
                height="auto"
                className="mb-3"
              />
            </div>
            <div>Iniciando app...</div>
          </div>
        ) : !auth.isAuthenticated ? (
          <Login
            forbiddenAccess={error}
            isSubmitting={isSubmitting}
            onSubmit={handleLoginSubmit}
          />
        ) : (
          <Routes />
        )}
      </Router>
      <ToastBox
        timerExpires={4000}
        position="top-right"
        pauseOnHover={true}
        intent="success"
      />
      <audio ref={notificationAudio} src="./assets/sounds/notification.mp3" />
    </div>
  );
};

export default App;
