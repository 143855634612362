import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_MENU, PROVIDER_MENU } from "../utils/constants";

const Sidebar = () => {
  const auth = useSelector((state) => state.auth);
  const hideSidebarMenu = (e) => {
    document.getElementById("sidebar").classList.remove("active");
    document.getElementById("overlay").classList.remove("active");
  };

  const menu = auth.user.isProvider ? PROVIDER_MENU : ADMIN_MENU;

  return (
    <>
      <aside id="sidebar" className="">
        <div className="d-flex sidebar-header align-items-center justify-content-between">
          <NavLink className="d-flex align-items-center brand" to="#">
            <img src={"/logo.svg"} width={42} height={42} className="mr-3" /> Gur&uacute;
          </NavLink>
          <button
            className="btn mr-2 btn-light d-lg-none"
            onClick={hideSidebarMenu}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <nav className="nav flex-column">
          {menu.map((menuItem, index) => (
            <li key={index} className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={menuItem.path}
                exact
              >
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </NavLink>
            </li>
          ))}
        </nav>
      </aside>
      <div id="overlay" onClick={hideSidebarMenu}></div>
    </>
  );
};

export default Sidebar;
