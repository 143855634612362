import React from "react";

const OpenCheckBox = ({checked, onChange, label}) => {
    return <div className="form-check form-switch">
    <input
      className="form-check-input"
      type="checkbox"
      id="open-store"
      checked={checked}
      onChange={onChange}
    />
    <label className="form-check-label font-weight-bold" htmlFor="open-store">
      {label}
    </label>
  </div>
};

export default OpenCheckBox;