import React from "react";
import firebase from "firebase";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/AuthActions";

const ProfileButton = ({ user, onPress, showProfileMenu }) => {
  const dispatch = useDispatch();

  const handleLogOut = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signOut()
      .then(() => dispatch(logout()));
  };

  return (
    <div className={`profile${showProfileMenu ? " active" : ""}`}>
      <div className="icon_wrap" onClick={onPress}>
        <i className="fas fa-user-circle user-icon"></i>
        <span className="name">
          {user.isProvider
            ? ` ${user.representative.firstName} ${user.representative.lastName} `
            : user.name}
        </span>
        <i className="fas fa-chevron-down"></i>
      </div>

      <div className="profile_dd">
        <ul className="profile_ul">
          {/* <li className="profile_li">
            <a className="profile" href="#">
              <span className="picon">
                <i className="fas fa-user-alt"></i>{" "}
              </span>
              Profile
            </a>
            <div className="btn">My Account</div>
          </li>
          <li>
            <a className="address" href="#">
              <span className="picon">
                <i className="fas fa-map-marker"></i>
              </span>
              Address
            </a>
          </li>
          <li>
            <a className="settings" href="#">
              <span className="picon">
                <i className="fas fa-cog"></i>
              </span>
              Settings
            </a>
          </li> */}
          <li>
            <a className="logout" onClick={handleLogOut}>
              <span className="picon">
                <i className="fas fa-sign-out-alt"></i>
              </span>
              Cerrar sesión
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileButton;
