import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDqvPPBCmm0wSqAcYXSM9fTKmHnJLCfHvM",
  authDomain: "thefoodproject-97b08.firebaseapp.com",
  databaseURL: "https://thefoodproject-97b08.firebaseio.com",
  projectId: "thefoodproject-97b08",
  storageBucket: "thefoodproject-97b08.appspot.com",
  messagingSenderId: "268466194019",
  appId: "1:268466194019:web:680661b730c59420599a56",
  measurementId: "G-8H6KEYVJZL",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const messaging = firebase.messaging();

if (window.location.hostname === "localhost") {
  //firestore.useEmulator("localhost", 8080);
}

export default firebase;
