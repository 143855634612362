import React, { useState, useEffect } from "react";
import { firestore } from "../utils/Firebase";

const useNotifications = (recipientId, limit = 5) => {
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    const subscription = firestore
      .collection("notifications")
      .where("recipient", "==", recipientId)
      .orderBy("sentAt", "desc")
      .limit(limit)
      .onSnapshot((snapshot) => {
        const _notifications = [];
        let _notSeen = 0;
        snapshot.docs.forEach((notification) => {
          if (notification.data().seen === false) _notSeen++;

          _notifications.push({
            id: notification.id,
            ...notification.data(),
          });
        });

        setNotifications({ notSeen: _notSeen, list: _notifications });
      });
    return () => {
      subscription();
    };
  }, [recipientId]);

  return notifications;
};

export default useNotifications;
