import { createReducer } from "@reduxjs/toolkit";
import { login, update, logout } from "../actions/AuthActions";

const auth = createReducer(
  {
    isAuthenticated: false,
    user: null,
  },
  {
    [login]: (state, action) => ({
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    }),
    [update]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [logout]: (state) => ({
      ...state,
      isAuthenticated: false,
      user: null,
    }),
  }
);

export default auth;
