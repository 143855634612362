import { createAction } from "@reduxjs/toolkit";

export const login = createAction("auth/login", (user) => ({
  payload: {
    isAuthenticated: true,
    user: user,
  },
}));

export const update = createAction("auth/update", (user) => ({
  payload: user,
}));

export const logout = createAction("auth/logout");
