import React from "react";
import Button from "react-bootstrap/Button";
import ReactTimeAgo from "react-time-ago";

const Notification = ({ icon, title, subTitle, date, seen, type, action }) => {
  console.log("read: ", seen);
  return (
    <li className={`${type}${seen === false ? " unread" : ""}`}>
      <div className="icon-container">
        <div className="icon-circle rounded-circle">
          <span className="icon">{icon}</span>
        </div>
      </div>
      <div className="notify_data">
        <div className="title">{title}</div>
        <div className="sub_title">{subTitle}</div>
        <small className="date">
          <ReactTimeAgo date={new Date(date)} timeStyle="round-minute" />
        </small>
      </div>
      {action && (
        <div className="notify_action">
          <Button variant={seen === false ? "primary" : "light"} size="sm" onClick={action}>
            Ver
          </Button>
        </div>
      )}
    </li>
  );
};

export default Notification;
