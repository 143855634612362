import { createAction } from "@reduxjs/toolkit";

export const addNotification = createAction("notifications/add", (data) => ({
  payload: {
    data,
  },
}));

export const removeNotification = createAction(
  "notifications/remove",
  (data) => ({
    payload: data,
  })
);
