import React from "react";
import Spinner from "react-bootstrap/Spinner";

const ContentLoader = () => {
  return (
    <div className="d-flex align-items-center m-3">
      <Spinner
      className="mr-2"
        variant="primary"
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      Cargando...
    </div>
  );
};

export default ContentLoader;
