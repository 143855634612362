import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MainLayout from "./layouts/MainLayout";
import ContentLoader from "./components/ContentLoader";

// ADMIN LAYOUTS
const HomeAdmin = lazy(() => import("./layouts/admin/Home"));
const Creator = lazy(() => import("./layouts/admin/Creator"));
const Reports = lazy(() => import("./layouts/admin/Reports"));
const Payments = lazy(() => import("./layouts/admin/Payments"));
const Products = lazy(() => import("./layouts/admin/Products"));
const NewProduct = lazy(() => import("./layouts/admin/NewProduct"));
const EditProduct = lazy(() => import("./layouts/admin/EditProduct"));
const Providers = lazy(() => import("./layouts/admin/Providers"));
const NewProvider = lazy(() => import("./layouts/admin/NewProvider"));
const Users = lazy(() => import("./layouts/admin/Users"));
const Deliverers = lazy(() => import("./layouts/admin/Deliverers"));
// PROVIDER LAYOUTS
const HomeProvider = lazy(() => import("./layouts/provider/Home"));
const ProductsProvider = lazy(() => import("./layouts/provider/Products"));
const History = lazy(() => import("./layouts/provider/History"));
// OTHERS
const Notifications = lazy(() => import("./layouts/Notifications"));
const FourZeroFour = lazy(() => import("./layouts/FourZeroFour"));

const Routes = () => {
  const auth = useSelector((state) => state.auth);
  return (
    <MainLayout>
      <Suspense fallback={<ContentLoader />}>
        <Switch>
          {auth.user.accessLevel === 10 ? (
            <>
              <Route path="/" component={HomeAdmin} exact />
              <Route path="/creator" component={Creator} exact />
              <Route path="/creator/products" component={Products} exact />
              <Route path="/creator/products/new" component={NewProduct} />
              <Route
                path="/creator/products/edit/:id"
                component={EditProduct}
              />
              <Route path="/creator/providers" component={Providers} exact />
              <Route
                path="/creator/providers/new"
                component={NewProvider}
                exact
              />
              <Route path="/creator/deliverers" component={Deliverers} exact />
              <Route path="/users" component={Users} />
              <Route path="/reports" component={Reports} />
              <Route path="/payments" component={Payments} />
              <Route path="/notifications" component={Notifications} exact />
            </>
          ) : (
            /* Provider */
            <>
              <Route path="/" component={HomeProvider} exact />
              <Route path="/products" component={ProductsProvider} exact />
              <Route path="/history" component={History} exact />
              <Route path="/notifications" component={Notifications} exact />
            </>
          )}

          <Route component={FourZeroFour} />
        </Switch>
      </Suspense>
    </MainLayout>
  );
};

export default Routes;
