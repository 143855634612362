import { configureStore } from "@reduxjs/toolkit";
import auth from "./reducers/Auth";
import Providers from "./reducers/Providers";
import Products from "./reducers/Products";
import Users from "./reducers/Users";
import Deliverers from "./reducers/Deliverers";
import Notifications from "./reducers/Notifications";
import Settings from "./reducers/Settings";

const store = configureStore({
  reducer: {
    auth,
    Products,
    Users,
    Providers,
    Deliverers,
    Notifications,
    Settings,
  },
});

export default store;
