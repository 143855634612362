import React from "react";

const NotificationsButton = ({
  children,
  onPress,
  notificationsCount,
  showNotificationsList,
}) => {
  return (
    <div className={`notifications${showNotificationsList ? " active" : ""}`}>
      <div className="icon_wrap" onClick={onPress}>
        {notificationsCount > 0 && <span className="badge"> </span>}
        <i className="far fa-bell"></i>
      </div>
      <div className="notification_dd">{children}</div>
    </div>
  );
};

export default NotificationsButton;
