import React, { useState } from "react";
import BaseModal from "./BaseModal";

const OpenAppModal = ({ visible, title, children, onAccept, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <BaseModal
      visible={visible}
      isProcessing={isProcessing}
      title={title}
      onAccept={async () => {
        setIsProcessing(true);
        await onAccept();
        setIsProcessing(false);
        onClose();
      }}
      onClose={onClose}
    >
      {children}
    </BaseModal>
  );
};

export default OpenAppModal;
