import { createReducer } from "@reduxjs/toolkit";
import { fetchUsers } from "../actions/Users";

const users = createReducer(
  { users: [], lastUser: {} },
  {
    [fetchUsers]: (state, { payload }) => ({
      users: payload.users,
      lastUser: payload.lastUser,
    }),
  }
);

export default users;
