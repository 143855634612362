import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { firestore } from "../../utils/Firebase";
import NotificationsButton from "./NotificationsButton";
import ProfileButton from "./ProfileButton";
import NotificationsList from "../Notifications/NotificationsList";
import useNotifications from "../../hooks/useNotifications";

const ProfileNavigation = ({ user }) => {
  const [popUpsState, setPopUpsState] = useState({
    notificationsVisible: false,
    profileVisible: false,
  });
  const notifications = useNotifications(
    user.isProvider ? user.id : "admin",
    5
  );

  const handleNotificationVisibility = () => {
    setPopUpsState((prevState) => ({
      ...prevState,
      notificationsVisible: !prevState.notificationsVisible,
      profileVisible: false,
    }));
  };

  const handleProfileMenuVisibility = () => {
    setPopUpsState((prevState) => ({
      ...prevState,
      notificationsVisible: false,
      profileVisible: !prevState.profileVisible,
    }));
  };

  return (
    <div className="navbar_right">
      <NotificationsButton
        notificationsCount={notifications.list ? notifications.notSeen : 0}
        onPress={async () => {
          handleNotificationVisibility();
          if (notifications.list.length > 0 && notifications.notSeen > 0) {
            //Update notifications seen propery
            const batch = firestore.batch();

            notifications.list.forEach((notification) => {
              if (!notification.seen) {
                const docRef = firestore
                  .collection("notifications")
                  .doc(notification.id);
                batch.update(docRef, { seen: true });
              }
            });

            batch.commit();
          }
        }}
        showNotificationsList={popUpsState.notificationsVisible}
      >
        {notifications.list && (
          <>
            <NotificationsList
              afterNotificationAction={handleNotificationVisibility}
              list={notifications.list ?? []}
            />
            {notifications.list.length > 0 && (
              <NavLink
                className="btn btn-sm btn-block py-2 view_all"
                to="/notifications"
                onMouseUp={handleNotificationVisibility}
              >
                {`Ver todas ${
                  notifications.notSeen > 5 ? `(${notifications.notSeen})` : ""
                }`}
              </NavLink>
            )}
          </>
        )}
      </NotificationsButton>

      <ProfileButton
        user={user}
        onPress={handleProfileMenuVisibility}
        showProfileMenu={popUpsState.profileVisible}
      />
    </div>
  );
};

export default ProfileNavigation;
